import { useState } from "react";
import { useTranslation } from "react-i18next";
import { startProfiling } from "api/profiler";
import { useErrorHandler } from "contexts/errorContext";
import { getCachedConfiguration, setCacheConfiguration } from "utils/functions/configurationUtil";
import { transformCurrency } from "utils/functions/currencyModUtil";
import TogglerButton from "components/ui/TogglerButton";
import Button from "components/ui/Button";

import preProfilerPicture from "../../assets/images/pre-profiler-icon.png";
import * as c from "./PreProfiler.module.css";

interface PreProfilerProps {
  onGetStarted: () => void;
  onToc: (show: boolean, page: number) => void;
  loadingQuestions: boolean;
  canShowSurveyForm: boolean;
}

function PreProfiler({
  onGetStarted,
  onToc,
  loadingQuestions: surveyFetch,
  canShowSurveyForm,
}: PreProfilerProps) {
  const [canContinue, setCanContinue] = useState(false);
  const [isProfilerStarting, setIsProfilerStarting] = useState(false);
  const { t } = useTranslation();
  const configuration = getCachedConfiguration();
  const amount = transformCurrency(
    configuration?.currency_name ?? "",
    configuration?.profiler_reward!,
  );
  const translatedString = t("INBRAIN_THEME.NEW_PRE_PROFILER.SUB_DESCRIPTION", { amount });
  const handleError = useErrorHandler();

  const startProfiler = () => {
    if (configuration?.is_profiler_survey_started) {
      onGetStarted();
      return;
    }

    setIsProfilerStarting(true);

    startProfiling(
      configuration?.hashed_publisher_app_uid ?? "",
      configuration?.product_type as number,
    )
      .then(() => {
        setCacheConfiguration({ ...configuration, is_profiler_survey_started: true });
        setIsProfilerStarting(false);
        onGetStarted();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  function hasReward() {
    return configuration?.profiler_reward! > 0;
  }

  return (
    <>
      <div className={c["pre-profiler-wrapper"]}>
        <div className={c["title-container"]}>
          <div className={c["header-wrapper"]}>
            <img src={preProfilerPicture} alt="Pre profile picture" />
            <div className={c["pre-profiler-title-wrapper"]}>
              <span className={c["pre-profiler-title"]}>
                {t("INBRAIN_THEME.NEW_PRE_PROFILER.DESCRIPTION")}
              </span>
              {hasReward() && (
                <span className={c["pre-profiler-earn"]}>
                  <div dangerouslySetInnerHTML={{ __html: translatedString }} />
                </span>
              )}
            </div>
          </div>
        </div>
        <div className={c["accept-toc-toggler-container"]}>
          <div className={c["text-wrapper"]}>
            <p className="text">
              {t("INBRAIN_THEME.NEW_PRE_PROFILER.TOC_AND_PP.DESCRIPTION")} <br />
              <span className={c["toc-and-pp"]} onClick={() => onToc(true, 0)}>
                {t("INBRAIN_THEME.NEW_PRE_PROFILER.TOC_AND_PP.TOC")}
              </span>
              <br />
              <span className={c["toc-and-pp"]} onClick={() => onToc(true, 1)}>
                {t("INBRAIN_THEME.NEW_PRE_PROFILER.TOC_AND_PP.PP")}
              </span>
            </p>
          </div>
          <div className={c["toggler-wrapper"]}>
            <TogglerButton toggled={false} onClick={() => setCanContinue(!canContinue)} />
          </div>
        </div>
      </div>
      <div className={c["button-container"]}>
        <Button
          text={t("INBRAIN_THEME.PRE_PROFILER.BUTTON_TEXT")}
          onClick={startProfiler}
          disabled={canContinue || isProfilerStarting}
          isLoading={(surveyFetch && canShowSurveyForm) || isProfilerStarting}
        />
      </div>
    </>
  );
}

export default PreProfiler;
