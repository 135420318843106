declare global {
  interface Window {
    fwSettings?: {
      widget_id: string;
      locale: string;
    };
    FreshworksWidget?: {
      (...args: any[]): void;
      q: any[];
    };
  }
}

import { useEffect } from "react";
import { useParams } from "react-router-dom";

import footerLogo from "assets/images/footer-logo.svg";
import * as c from "./UnitedSupportPage.module.css";

function UnitedSupportPage(): JSX.Element {
  const { userId, language } = useParams<{ userId: string; language: string }>();

  useEffect(() => {
    const widgetId = language === "es" ? "73000004875" : "73000004874";
    const siteLanguage = language === "es" ? "Spanish" : "English";

    window.fwSettings = {
      widget_id: widgetId,
      locale: language as string,
    };

    if (typeof window.FreshworksWidget !== "function") {
      const FreshworksWidget = function () {
        FreshworksWidget.q.push(arguments);
      };
      FreshworksWidget.q = [] as any[];
      window.FreshworksWidget = FreshworksWidget;
    }

    const script = document.createElement("script");
    script.src = `https://widget.freshworks.com/widgets/${widgetId}.js`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.FreshworksWidget) {
        window.FreshworksWidget("open");
        window.FreshworksWidget("hide", "launcher");
        window.FreshworksWidget("prefill", "ticketForm", {
          type: "Other",
          custom_fields: {
            cf_brand: "inBrain",
            cf_country745149: "USA",
            cf_language639845: siteLanguage,
            cf_inbrain_user_id: userId,
          },
        });
        window.FreshworksWidget("hide", "ticketForm", [
          "type",
          "custom_fields.cf_offer_name",
          "custom_fields.cf_brand",
          "custom_fields.cf_country745149",
          "custom_fields.cf_language639845",
          "custom_fields.cf_inbrain_user_id",
          "group_id",
        ]);

        const observer = new MutationObserver(() => {
          const frameWrapper = document.querySelector("#freshworks-frame-wrapper");
          if (frameWrapper) {
            frameWrapper.setAttribute(
              "style",
              "width: 100%; max-width: 800px !important; position: fixed !important; margin-left: auto; margin-right: auto; left: 0 !important; top: 120px; right: 0; text-align: center;",
            );
            observer.disconnect();
          }
        });

        observer.observe(document.body, { childList: true, subtree: true });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [language]);

  return (
    <div
      style={{
        background:
          "linear-gradient(214.85deg, rgb(0, 168, 134) 0%, rgb(0, 108, 159) 100%) 0% 0% / contain",
        minHeight: "100vh",
      }}
    >
      <div className={c["footer-container"]}>
        <div className={c["terms"]}>
          <img src={footerLogo} alt="footer-logo" />
          <a href="https://www.inbrain.ai/terms-and-conditions/terms-of-service" target="_blank">
            Terms of Services
          </a>
          <a href="https://www.inbrain.ai/terms-and-conditions/privacy-policy" target="_blank">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
}

export default UnitedSupportPage;
