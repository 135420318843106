import { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import * as c from "./OfferModalDescriptionTask.module.css";

interface OfferModalDescriptionTaskProps {
  description: string[];
  isMobileOs: string;
}

const OfferModalDescriptionTask = ({ description, isMobileOs }: OfferModalDescriptionTaskProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const characterLimit = isMobileOs == "" ? 150 : 115;

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const fullDescription = description.join(" ");

  const truncatedDescription =
    fullDescription.length > characterLimit
      ? fullDescription.slice(0, characterLimit) + "..."
      : fullDescription;

  return (
    <div className={c.task}>
      <div className={`${c["task-content-container"]} ${isExpanded ? c.expanded : ""}`}>
        {!isExpanded && <span className={c.descriptionText}>{truncatedDescription}</span>}
        {isExpanded &&
          description!.map((description, index) => (
            <span key={index} className={c["description-text-expanded"]}>
              {description}
            </span>
          ))}
        <button onClick={toggleExpanded} className={c["read-more-button"]}>
          {isExpanded ? <FiChevronUp /> : <FiChevronDown />}
          {isExpanded ? "Read Less" : "Read More"}
        </button>
      </div>
    </div>
  );
};

export default OfferModalDescriptionTask;
