import { useEffect, useState } from "react";
import { useErrorHandler } from "contexts/errorContext";
import { getProfilingQuestions } from "api/profiler";
import {
  getCachedConfiguration,
  getPublisherFlags,
  setCacheConfiguration,
} from "utils/functions/configurationUtil";
import { SurveyQuestion } from "interfaces/SurveyQuestion";
import PreProfiler from "components/profiler/PreProfiler";
import ProfilerSurveyForm from "components/profiler/ProfilerSurveyForm";
import ProfilerSurveySuccess from "components/profiler/ProfilerSurveySuccess";
import Toc from "components/preProfiler/Toc";

import * as c from "./ProfilerPage.module.css";
import { ProductTypes } from "utils/constants/enums";
import * as systemNotificationUtils from "utils/functions/systemNotificationUtil";
import { SurveyOutcomeEvent } from "interfaces/SurveyOutcomeEvent";
import { SurveyOutcomeTypes } from "interfaces/SurveyOutcomeTypes";
import { useNavigate } from "react-router-dom";

interface ProfilerPageProps {
  onFinish: () => void;
}

function ProfilerPage({ onFinish }: ProfilerPageProps) {
  const [questions, setQuestions] = useState<SurveyQuestion[] | undefined>();
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [showProfiler, setShowProfiler] = useState(false);
  const [canShowSurveyForm, setCanShowSurveyForm] = useState(false);
  const [surveyFinished, setSurveyFinished] = useState(false);
  const [showToc, setShowToc] = useState({ show: false, page: 0 });
  const handleError = useErrorHandler();
  const configuration = getCachedConfiguration();
  const publisherFlags = getPublisherFlags();
  const navigate = useNavigate();

  const handleProfilerFinished = () => {
    setShowProfiler(false);

    if (configuration?.product_type === ProductTypes.NativeSurveys) {
      const surveyOutcomeEvent: SurveyOutcomeEvent = {
        surveyId: "profiler_survey",
        categoryIds: [],
        placementId: "",
        userReward: configuration.profiler_reward!,
        outcomeType: SurveyOutcomeTypes.Complete,
      };
      systemNotificationUtils.notifySurveyOutcome(surveyOutcomeEvent);

      if (publisherFlags?.isNativeSurveysInAppRoutingEnabled) {
        systemNotificationUtils.notifyNativeSurveyClosed();
      } else {
        setCacheConfiguration({ ...configuration, profiler_questions_required: false });

        navigate("/surveys");
      }
    } else if (configuration?.product_type === ProductTypes.SurveysWall) {
      systemNotificationUtils.notifySurveyClosed();

      if (typeof onFinish === "function") {
        onFinish();
      }
    } else {
      const queryParams = new URLSearchParams(location.search);
      const clientRedirectLink = queryParams.get("clientRedirectLink");
      const decodedUrl = decodeURIComponent(clientRedirectLink!);
      window.location.href = decodedUrl;
    }
  };

  const handleGetStarted = () => {
    setCanShowSurveyForm(true);
  };

  const handleToc = (show: boolean, page: number) => {
    setShowToc({ show, page });
  };

  useEffect(() => {
    const updateViewportHeight = () => {
      const viewportHeight = window.innerHeight;
      document.body.style.setProperty("--viewportHeight", `${viewportHeight}px`);
    };

    window.addEventListener("resize", updateViewportHeight);
    updateViewportHeight();

    return () => window.removeEventListener("resize", updateViewportHeight);
  }, []);

  useEffect(() => {
    if (loadingQuestions || !canShowSurveyForm) return;
    setShowProfiler(true);
  }, [loadingQuestions, canShowSurveyForm]);

  useEffect(() => {
    getProfilingQuestions(configuration?.hashed_publisher_app_uid ?? "")
      .then((survey) => {
        setQuestions(survey);
        setLoadingQuestions(false);
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  return (
    <div
      className={c["profiler-container"]}
      style={{
        height: showToc.show ? "unset" : undefined,
      }}
    >
      {showToc.show && <Toc page={showToc.page} goBack={handleToc} />}
      {!surveyFinished && !showProfiler && !showToc.show && (
        <PreProfiler
          onGetStarted={handleGetStarted}
          loadingQuestions={loadingQuestions}
          canShowSurveyForm={canShowSurveyForm}
          onToc={handleToc}
        />
      )}
      {!surveyFinished && showProfiler && !showToc.show && (
        <ProfilerSurveyForm questions={questions} onSurveyFinished={setSurveyFinished} />
      )}
      {surveyFinished && showProfiler && (
        <ProfilerSurveySuccess onFinish={handleProfilerFinished} />
      )}
    </div>
  );
}

export default ProfilerPage;
