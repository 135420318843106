import { useState } from "react";

import * as c from "./TogglerButton.module.css";

interface TogglerButtonProps {
  toggled: boolean;
  onClick: (value: boolean) => void;
}

function TogglerButton({ toggled, onClick }: TogglerButtonProps) {
  const [isToggled, toggle] = useState(toggled);

  const callback = () => {
    toggle(!isToggled);
    onClick(!isToggled);
  };

  return (
    <div className={c["button-cover"]}>
      <div className={c["button"]}>
        <input type="checkbox" className={c["checkbox"]} checked={isToggled} onChange={callback} />
        <div className={c["knob"]}></div>
        <div className={c["layer"]}></div>
      </div>
    </div>
  );
}

export default TogglerButton;
