import { useEffect, useMemo, useRef, useState } from "react";
import { useErrorHandler } from "contexts/errorContext";
import { useBreakpoint } from "hooks/useBreakpoint";
import { getLink } from "api/offers";
import { Goal } from "interfaces/Goal";
import { OfferStatuses } from "interfaces/Offer";
import { getCachedConfiguration } from "utils/functions/configurationUtil";
import { transformCurrency } from "utils/functions/currencyModUtil";
import { TiDeviceDesktop, TiVendorAndroid } from "react-icons/ti";
import { TiVendorApple } from "react-icons/ti";
import { IoIosArrowRoundDown } from "react-icons/io";
import RewardModalDescriptionTask from "./RewardModalDescriptionTask";
import RewardModalGoalTask from "./RewardModalGoalTask";
import blueArrow from "../../../assets/images/qr-blue-arrow.png";
import * as c from "./RewardModalContent.module.css";

interface RewardModalContentProps {
  modalContent: {
    attemptedAt: string;
    goals?: Goal[];
    isCompleted?: boolean;
    description?: string[];
    platforms: string[];
    operatingSystems?: string[];
    rewardIac: number;
    title: string;
    thumbnailUrl: string;
    heroImageUrl?: string;
    offerId: number;
    offerStatus: OfferStatuses;
  };
  onOpenQrModal: (qrCode: string) => void;
  onScrollTreshold: (value: boolean) => void;
}

type Platform = "Android" | "iOS" | "Windows";

const platformIcons: Record<Platform, JSX.Element> = {
  Android: <TiVendorAndroid color="#A6D864" />,
  iOS: <TiVendorApple />,
  Windows: <TiDeviceDesktop size={19} color="#00a5ed" />,
};

const RewardModalContent = ({
  modalContent,
  onOpenQrModal,
  onScrollTreshold,
}: RewardModalContentProps) => {
  const handleError = useErrorHandler();
  const { isMobile, isTablet, isDesktop } = useBreakpoint();
  const {
    attemptedAt,
    goals,
    isCompleted,
    description,
    platforms,
    operatingSystems,
    rewardIac,
    title,
    thumbnailUrl,
    heroImageUrl,
    offerId,
    offerStatus,
  } = modalContent;
  const configuration = getCachedConfiguration();
  const currency = transformCurrency(configuration!.currency_name!, rewardIac);
  const [daysRemaining, setDaysRemaining] = useState<number | null>(null);
  const [hoursRemaining, setHoursRemaining] = useState<number | null>(null);
  const [url, setUrl] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const modalContentRef = useRef<HTMLDivElement>(null);
  const SCROLL_THRESHOLD = 100;

  const indexZeroTitle = useMemo(() => {
    if (goals && goals.length > 0) {
      return goals![0].title;
    }
  }, [goals]);

  useEffect(() => {
    const updateViewportHeight = () => {
      const viewportHeight = window.innerHeight;
      document.body.style.setProperty("--viewportHeight", `${viewportHeight}px`);
    };

    window.addEventListener("resize", updateViewportHeight);
    updateViewportHeight();

    return () => window.removeEventListener("resize", updateViewportHeight);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getLink(offerId, configuration!.hashed_publisher_app_uid!)
      .then((data) => {
        setUrl(data.link);
        setQrCode(data.qrCode);
        setIsLoading(false);
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date();
      const lastAttemptDate = new Date(attemptedAt);
      const diffInMs = now.getTime() - lastAttemptDate.getTime();

      const totalDurationMs = 7 * 24 * 60 * 60 * 1000;
      const remainingMs = totalDurationMs - diffInMs;

      if (remainingMs > 0) {
        const remainingDays = Math.floor(remainingMs / (1000 * 60 * 60 * 24));
        const remainingHours = Math.floor((remainingMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        if (remainingDays > 0) {
          setDaysRemaining(remainingDays);
          setHoursRemaining(null);
        } else {
          setDaysRemaining(null);
          setHoursRemaining(remainingHours);
        }
      } else {
        setDaysRemaining(0);
        setHoursRemaining(0);
      }
    };

    calculateTimeRemaining();
  }, [attemptedAt]);

  useEffect(() => {
    const modal = modalContentRef.current;
    if (modal) {
      modal.addEventListener("scroll", handleModalScroll);
    }
    return () => {
      if (modal) {
        modal.removeEventListener("scroll", handleModalScroll);
      }
    };
  }, []);

  const getTotalCompletedReward = (goals: Goal[]) => {
    const reward = goals.reduce((total, goal) => {
      if (goal.isCompleted) {
        return total + goal.rewardIac;
      }
      return total;
    }, 0);

    return transformCurrency(configuration!.currency_name!, reward);
  };

  const handleModalScroll = () => {
    const modal = modalContentRef.current;
    if (modal) {
      const scrollPosition = modal.scrollTop;

      if (heroImageUrl) {
        scrollPosition >= BUTTON_THRESHOLD ? onScrollTreshold(true) : onScrollTreshold(false);
      }

      const maxScrollHeight = modal.scrollHeight - modal.clientHeight;
      const thresholdPosition = maxScrollHeight - SCROLL_THRESHOLD;

      if (scrollPosition < thresholdPosition) {
        setIsButtonVisible(true);
      } else {
        setIsButtonVisible(false);
      }
    }
  };

  const scrollModalContent = () => {
    const modal = modalContentRef.current;
    if (modal) {
      modal.scrollBy({ top: 400, behavior: "smooth" });
    }
  };

  const detectMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "iOS";
    if (/android/i.test(userAgent)) return "Android";
    return "";
  };

  const BUTTON_THRESHOLD = detectMobileOS() === "" ? 280 : 180;

  const mobileOS = detectMobileOS();

  const isOfferOpen = offerStatus === OfferStatuses.Open;

  const isWebAvailable =
    operatingSystems?.includes("Windows") || operatingSystems?.includes("MacOS");

  return (
    <div className={c["modal-content"]}>
      <div
        className={`${c["modal-content-container"]} ${detectMobileOS() != "" && c["no-web-style"]}`}
        ref={modalContentRef}
      >
        {heroImageUrl && (
          <div
            className={`${c["hero-image-wrapper"]} ${detectMobileOS() != "" && c["no-web-style"]}`}
          >
            <img src={heroImageUrl} alt="Hero Image" />
          </div>
        )}
        <div className={c["modal-content-task-wrapper"]}>
          <div className={c.header}>
            <img src={thumbnailUrl} alt="Game Icon" className={c.icon} />
            <div className={c["header-text"]}>
              <span className={c["title"]}>{title}</span>
              <div className={c["platform-icons"]}>
                {platforms &&
                  platforms.length > 0 &&
                  platforms.map((platform) => (
                    <span key={platform}>{platformIcons[platform as Platform] || null}</span>
                  ))}
              </div>
              <span className={c["sub-title"]}>Earn up to {currency} with this offer</span>
              <span className={c["earn-up"]}>
                {isCompleted ? currency : getTotalCompletedReward(goals!)} / {currency}
              </span>
            </div>
          </div>

          {!isMobile && qrCode !== "" && (
            <div
              className={`${c["qr-content-container"]} ${
                !isWebAvailable ? c["no-web-style"] : c["web-style"]
              }`}
            >
              {isWebAvailable && isOfferOpen && (
                <div className={c["get-started-button-wrapper"]}>
                  <a
                    href={url}
                    target="_blank"
                    className={c["sign-up-button"]}
                    style={{ fontSize: "14px" }}
                  >
                    Get started on web
                  </a>
                </div>
              )}
              {!isOfferOpen && <div>Offer Closed</div>}
              {isWebAvailable && isOfferOpen && <span className={c["qr-separator"]}>OR</span>}
              <div className={c["qr-code-container"]}>
                <div className={c["qr-code-text-wrapper"]}>
                  <span>Click here to start on your phone</span>
                </div>
                <div className={c["qr-blue-arrow-wrapper"]}>
                  <img src={blueArrow} alt="Blue Arrow" />
                </div>
                <div
                  style={{
                    backgroundColor: !isOfferOpen ? "#cbcbcb" : "",
                    pointerEvents: !isOfferOpen ? "none" : "auto",
                  }}
                  className={c["qr-platform-icons"]}
                  onClick={() => onOpenQrModal(qrCode)}
                >
                  {operatingSystems !== null &&
                    operatingSystems?.length !== 0 &&
                    operatingSystems
                      ?.filter((platform) => platform === "Android" || platform === "iOS")
                      .map((platform) => (
                        <span key={platform}>{platformIcons[platform as Platform] || null}</span>
                      ))}
                </div>
              </div>
            </div>
          )}
          {description && description.length && (
            <div className={c.tasks}>
              <RewardModalDescriptionTask description={description} isMobileOs={mobileOS} />
            </div>
          )}
          {goals && goals.length > 0 && (
            <div className={c.tasks}>
              {goals.map((goal, index) => {
                return (
                  <RewardModalGoalTask
                    key={goal.id}
                    goal={goal}
                    currencyName={configuration!.currency_name!}
                    index={index}
                    daysRemaining={daysRemaining!}
                    hoursRemaining={hoursRemaining!}
                    indexZeroTitle={indexZeroTitle}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className={c["button-wrapper"]}>
        {isButtonVisible && goals && goals.length > 3 && (
          <div className={c["scroll-down-button-wrapper"]}>
            <button className={c["scroll-down-button"]} onClick={scrollModalContent}>
              <IoIosArrowRoundDown size={"18px"} style={{ marginRight: "2px" }} /> More goals
            </button>
          </div>
        )}
        <a
          href={url}
          style={{
            pointerEvents: !isOfferOpen ? "none" : "auto",
            backgroundColor: !isOfferOpen ? "#cbcbcb" : "",
          }}
          onClick={(e) => {
            if (!isWebAvailable && !isMobile) {
              e.preventDefault();
              onOpenQrModal(qrCode);
            }
          }}
          target="_blank"
          className={`${c["sign-up-button"]} ${!isMobile && isLoading && c["disabled"]}`}
        >
          Get started and earn up to <span className={c.points}>&nbsp;{currency}</span>
        </a>
      </div>
    </div>
  );
};

export default RewardModalContent;
